import { Application } from '@hotwired/stimulus'
import controllers from './*_controller.js'
import applicationControllers from './application/*_controller.js'
import sharedControllers from './shared/*_controller.js'
import publicControllers from './public/*_controller.js'

const application = Application.start()

// Configure Stimulus development experience
application.debug = process.env.NODE_ENV === 'development'
window.Stimulus   = application

controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

applicationControllers.forEach((controller) => {
  application.register(controller.name.replace( 'application--', '' ), controller.module.default)
})

publicControllers.forEach((controller) => {
  application.register(controller.name.replace( 'public--', '' ), controller.module.default)
})

sharedControllers.forEach((controller) => {
  application.register(controller.name.replace( 'shared--', '' ), controller.module.default)
})
