import nprogress from 'nprogress'

document.addEventListener("turbo:click", function () {
    nprogress.start();
});

document.addEventListener("turbo:render", function () {
    nprogress.done();
    nprogress.remove();
});

document.addEventListener("turbo:frame-load", function () {
    nprogress.done();
    nprogress.remove();
});
