import AirDatepicker from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en';
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['date', 'datetime', 'time', 'month', 'range', 'year', 'input']

  isLive = () => this.datepicker

  initialize() { }

  connect() {
    // Register the teardown listener and start up DataTable.
    document.addEventListener('turbo:before-cache', this._teardown)

    if (this.hasDatetimeTarget) this.dateTimePicker()
    if (this.hasDateTarget) this.datePicker()
    if (this.hasTimeTarget) this.timePicker()
    if (this.hasMonthTarget) this.monthPicker()
    if (this.hasYearTarget) this.yearPicker()

  }

  execDatepicker(target, options) {

    options.locale = localeEn
    options.autoClose = true

    if (this.data.get('options')) {
      const extra_options = JSON.parse(this.data.get('options'))
      options = Object.assign({}, options, extra_options)
    }
    this.datepicker = new AirDatepicker(target, options)

    // if (this.data.get('url')) {
    //   const loadRemote = (selectedDates, dateStr, instance) => {
    //     let url = new URL(window.location.origin + this.data.get('url'))
    //     let search_params = url.searchParams
    //     search_params.set('date', dateStr)
    //     url.search = search_params.toString()
    //     Turbo.visit(url.toString())
    //   }
    //   this.datepicker.config.onChange.push(loadRemote)
    // }

  }

  dateTimePicker() {
    let options = { timepicker: true, dateFormat: 'dd/MM/yyyy' }
    this.execDatepicker(this.inputTarget, options)
  }

  datePicker() {
    let options = { dateFormat: 'dd/MM/yyyy' }
    this.execDatepicker(this.inputTarget, options)
  }

  timePicker() {
    let options = {}
    this.execDatepicker(this.inputTarget, options)
  }

  monthPicker() {
    let options = {
      minView: "months",
      view: "months",
      dateFormat: "MM yyyy"
    }
    this.execDatepicker(this.inputTarget, options)
  }

  yearPicker() {
    let options = {
      minView: "years",
      view: "years",
      dateFormat: "yyyy"
    }
    this.execDatepicker(this.inputTarget, options)
  }

  disconnect() {
    //this.datepicker.destroy();
  }

  _teardown = () => this.teardown()

  teardown(event) {
    if (!this.isLive()) return false

    document.removeEventListener('turbo:before-cache', this._teardown)

    this.datepicker.destroy()
    this.datepicker = undefined

  }
}
