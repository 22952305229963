import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["select", "selectized", "parent", "selectizedParent"]

  connect() {
    const placeholder = this.selectTarget.dataset.placeholder;
    const settings = this.selectTarget.dataset.settings || "{}"

    if (placeholder) settings['placeholder'] = placeholder

    let options = Object.assign({}, JSON.parse(settings), this.tags())

    this.selectized = $(this.selectTarget).selectize(options);

    this.initParent()
  }

  tags() {
    if (!this.selectTarget.dataset.tags) { return {} }

    return {
      delimiter: ",",
      persist: false,
      create: function (input) {
        return {
          value: input,
          text: input,
        };
      }
    }
  }

  initParent() {
    const parentSettings = JSON.parse(this.selectTarget.dataset.parent || '{}')

    if (!parentSettings) return false

    this.parent = document.getElementById(parentSettings.id)

    if (!this.parent) return false
    if (this.parent.dataset.initAsSelectizeParent === 'true') return false

    this.parent.dataset.initAsSelectizeParent = true

    const loadData = (clear) => (value) => {
      if (!value.length) return
      const child = this.selectized[0].selectize
      child.disable();
      if (clear) {
        child.clear();
        child.clearOptions();
      }
      child.load((callback) => {
        // Rails.ajax({
        //   url: parentSettings.search_url,
        //   type: 'GET',
        //   dataType: 'json',
        //   data: new URLSearchParams({ value }).toString(),
        //   success: (response) => {
        //     callback(response.data)
        //     child.enable()
        //   }
        // })
      })
    }

    const selectedParent = (this.parent.options[this.parent.selectedIndex].value)

    this.selectizedParent = $(this.parent).selectize({
      onChange: loadData(true)
    })

    if (selectedParent) loadData(false)(selectedParent)
  }

  disconnect() {
    this.selectized[0].selectize.destroy();
    this.selectizedParent && this.selectizedParent[0].selectize.destroy();
  }
}
