
import QRCodeStyling from 'qr-code-styling'
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    console.log('qrcode load')
    this.generate()
  }

  generate() {
    const url = this.element.dataset.url || ''
    const logo = this.element.dataset.logo || ''
    const height = this.element.dataset.height || 300
    const width = this.element.dataset.width || 300

    let colorPrimary = getComputedStyle(document.documentElement).getPropertyValue('--tblr-primary').trim();
    let colorSecondary = getComputedStyle(document.documentElement).getPropertyValue('--tblr-primary-darken').trim();

    this.qrCode = new QRCodeStyling({
      width: width,
      height: height,
      type: "svg",
      data: url,
      image: logo,
      dotsOptions: {
        color: "#6a1a4c",
        gradient: { "type": "radial", "rotation": 0, "colorStops": [{ "offset": 0, "color": colorPrimary }, { "offset": 1, "color": colorSecondary }] },
        type: "dots"
      },
      cornersSquareOptions: {
        type: 'square',
        color: 'black'
      },
      backgroundOptions: {
        color: "#fff",
      },
      imageOptions: {
        margin: 4
      }
    });

    this.qrCode.append(this.element);
  }

  download() {
    this.qrCode.download({ name: this.element.dataset.name, extension: "png" });
  }

}
