import { Controller } from '@hotwired/stimulus'
import screenfull from "screenfull"

export default class extends Controller {
  static targets = ['disabled']

  initialize() {
  }

  connect() {
    this.enableTootip()
    this.enablePopover()
    this.bugSnag()
    this.flashFrame()
    this.missingFrame()
    //this.submitFormEvents()
  }

  missingFrame() {

    document.addEventListener("turbo:frame-missing", async (event) => {
      event.preventDefault()

      const { response, visit } = event.detail
      if (process.env.NODE_ENV == "development") {
        console.warn(`Missing frame target: ${event.target.id}, Forced to replace content from body`)
      }

      const html = await response.clone().text()

      //Extract inner html from body
      const body = html.match(/<body[^>]*>([\s\S]*)<\/body>/i)[1]

      //Create a new document
      const doc = new DOMParser().parseFromString(body, "text/html")

      //Replace html doc to event target id
      document.getElementById(event.target.id).innerHTML = body
    });
  }

  flashFrame() {
    // Always replace flashes frame on frame render since turbo-frame does not support multiple frame replace at one request
    document.addEventListener("turbo:frame-render", (event) => {
      const { response } = event.detail.fetchResponse

      response.clone().text().then(html => {
        //Extract inner html from body frame id=flashes
        const frame = html.match(/<turbo-frame id="flashes">([\s\S]*)<\/turbo-frame>/i)[1]
        if (frame.length == 0) { return }
        //Replace html doc to event target id
        document.getElementById('flashes').innerHTML = frame
      });
    });
  }

  submitFormEvents() {
    document.addEventListener("turbo:submit-end", (event) => {
      if (event.detail.success) {
        if (window.mainModal) { window.mainModal.hide() }
      }
    });
  }

  enableTootip() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return window.bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl)
    })
    document.addEventListener("turbo:before-cache", function () {
      $('[data-bs-toggle="tooltip"]').tooltip('dispose');
    });
  }

  enablePopover() {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return window.bootstrap.Popover.getOrCreateInstance(popoverTriggerEl)
    })
    document.addEventListener("turbo:before-cache", function () {
      $('[data-bs-toggle="popover"]').popover('dispose');
    });
  }

  fullscreen(e) {
    //TODO: Toggle icon
    e.preventDefault()
    screenfull.toggle($('html')[0]);
  }

  bugSnag() {
    if (process.env.NODE_ENV != "development") {
      document.addEventListener("DOMContentLoaded", function () {
        const unknownUser = { dataset: { userId: null, accountId: null } }
        const userData = document.getElementsByTagName('body')[0].dataset || unknownUser
        const userEmail = userData.userEmail
        const userId = userData.userId

        window.Bugsnag.setUser(userId, userEmail)
      })
    }
  }
}
