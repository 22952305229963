import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["input"]

  connect() {
    console.log("Pincode controller connected");
    this.initForm();
  }

  initForm() {
    var inputs = document.querySelectorAll('[data-code-input]');
    // Attach an event listener to each input element
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('input', (e) => {
        // If the input field has a character, and there is a next input field, focus it
        if (e.target.value.length === e.target.maxLength && i + 1 < inputs.length) {
          inputs[i + 1].focus();
        }
        this.updateValue(e);
      });
      inputs[i].addEventListener('keydown', (e) => {
        // If the input field is empty and the keyCode for Backspace (8) is detected, and there is a previous input field, focus it
        if (e.target.value.length === 0 && e.keyCode === 8 && i > 0) {
          inputs[i - 1].focus();
        }
        this.updateValue(e);
      });
    }
  }

  updateValue(event) {
    let values = [];
    var inputs = document.querySelectorAll('[data-code-input]');
    for (let i = 0; i < inputs.length; i++) {
      values.push(inputs[i].value);
    }
    this.inputTarget.value = values.join("");
  }
}
