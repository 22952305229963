import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['disable', 'accept']

    connect() {
        if (this.hasDisableTarget && !this.isChecked()) {
            this.disable(true)
        }
    }

    toggle(event) {
        this.disable(!this.isChecked())
    }

    disable(state) {
        this.disableTargets.forEach((el) => {
            var items = el.querySelectorAll("input, button");
            items.forEach(function (item) {
                item.disabled = state;
            })
        })
    }

    isChecked() {
        return (this.acceptTarget.checked)
    }
}
