import Selectize from "@selectize/selectize";

$.extend(true, $.fn.selectize.defaults, {
  copyClassesToDropdown: false,
  dropdownClass: 'selectize-dropdown dropdown-menu',
})

// Selectize plugin to share the same options in multiple selectize selections

Selectize.define('shared_options', function(options) {
  var self = this

  Selectize.sharedOptions = Selectize.sharedOptions || {}

  self.setup = (function() {
    var original = self.setup;
    return function() {
      if (!Selectize.sharedOptions[self.settings.optionGroupName]) {
        Selectize.sharedOptions[self.settings.optionGroupName] = []
      }
      return original.apply(this, arguments);
    }
  })()

  self.removeItem = (function(value, silent) {
    var original = self.removeItem
    return function(value, silent) {
      let sharedItems = Selectize.sharedOptions[self.settings.optionGroupName]
      sharedItems.splice(sharedItems.indexOf(value), 1)
      return original.apply(this, arguments)
    }
  })()

  self.addItem = (function(value, silent) {
    var original = self.addItem
    return function(value, silent) {
      Selectize.sharedOptions[self.settings.optionGroupName].push(value)
      return original.apply(this, arguments)
    }
  })()

  self.search = (function(query) {
    var original = self.search;
    return function(query) {
      self.lastQuery = null;
      var result = original.apply(this, arguments)

      if (self.settings.hideSelected) {
        for (var i = result.items.length - 1; i >= 0; i--) {
          if (Selectize.sharedOptions[self.settings.optionGroupName].indexOf(result.items[i].id) !== -1) {
            result.items.splice(i, 1)
          }
        }
      }
      return result
    }
  })()
})
