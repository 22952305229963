import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["input"]

  connect() {
  }

  addPrefix(event) {
    let value = this.inputTarget.value
    if (!value.match(/^60|^\+60/)) {
      value = (value.length < 4) ? '+60' : ('+60' + value)
    }
    return this.inputTarget.value = value.replace(/(?!^\+60)(\+60)/g, '');
  }

  cleanup(event) {
    let value = this.inputTarget.value
    if (value === '+60') {
      return this.inputTarget.value = ''
    }
  }
}
