import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['modal']

  connect () {
    this.defaultModal = document.getElementById('modal')
    if (this.defaultModal) {
      this.mainModal = window.bootstrap.Modal.getOrCreateInstance(this.defaultModal)
      window.mainModal = this.mainModal
      this.resetModal()
    }
  }

  go (event) {
    this.item = event.currentTarget
    const url = this.item.dataset.url
    const remote = this.item.dataset.remote
    if (remote === 'true') {
      this.load(url)
    } else {
      Turbo.visit(url)
    }
  }

  post (event) {
    const url = this.item.dataset.url
    const params = this.item.dataset.params
    // Rails.ajax({
    //   type: 'POST',
    //   url: url,
    //   data: params
    // })
  }

  modal (event) {
    //event.preventDefault()

    this
      .defaultModal
      .querySelector('.modal-content')
      .innerHTML = this.loadingContent()

    //this.load(event.currentTarget.getAttribute('href'))

    this.mainModal.show()

    this.defaultModal
      .querySelector('.modal-dialog')
      .classList.add(`modal-${event.currentTarget.getAttribute('data-size')}`)

    this.defaultModal.addEventListener('hide.bs.modal', event => {
      document.dispatchEvent(new Event('datatable:reload'))
    })
  }

  async load (path) {
    const request = new FetchRequest('get', path, {})
    const response = await request.perform()
    if (response.ok) {
      const body = await response.text
      this.defaultModal.querySelector('.modal-content').innerHTML = body
      this._success()
    } else {
      this._errors()
    }
  }

  loadingContent () {
    return `<div class="modal-body">
              <div class="text-center">
                <span class="spinner-border text-azure"></span>
              </div>
            </div>`
  }

  resetModal () {
    this.defaultModal.addEventListener('hidden.bs.modal', function (e) {
      this.querySelector('.modal-dialog').classList.remove(
        'animated',
        'shake',
        'modal-lg',
        'modal-xl',
        'modal-sm'
      )
    })
  }

  _success () {}

  _complete () {}

  _errors () {}
}
