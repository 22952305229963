import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'switch'
  ]

  connect() {
    if (!this.hasSwitchTarget) {
      return;
    }

    if ((this.field().value !== '') || (this.switchTarget.checked)) {
      this.container().classList.remove('d-none')
      this.switchTarget.checked = true
    }
  }

  toggle() {
    if (this.container().classList.contains('d-none')) {
      this.container().classList.remove('d-none')
    } else {
      this.field().value = null
      this.container().classList.add('d-none')
    }
  }

  container() {
    return document.getElementById(this.switchTarget.dataset.containerTarget)
  }

  field() {
    return this.container().getElementsByTagName('input')[0]
  }
}
