$.ajaxSetup({
    headers:
    { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }
});

$(document).ajaxError(function (evt, jqXHR, ajaxOptions, errorThrown) {
    if (jqXHR.status === 401) {
        return location.reload();
    }
});
