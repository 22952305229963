import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["fieldValues", "kind"]

  connect() {
    if (this.hasKindTarget) {
      this.showHideValuesField(this.kindTarget.value === "options")
    }
  }

  toggleValues(event) {
    event.preventDefault()

    this.showHideValuesField(event.target.value === "options")
    this.fieldValuesTarget.querySelector("input").value = ""
  }

  showHideValuesField(state) {
    this.fieldValuesTarget.style.display = state ? "" : "none"
  }
}
