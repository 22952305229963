import Bugsnag from '@bugsnag/js'

if (['production', 'staging'].includes(process.env.NODE_ENV.toString())) {
  Bugsnag.start({
    apiKey: 'bugsnagapi',
    appVersion: process.env.BUILD_AT.toString(),
    releaseStage: process.env.RELEASE_STAGE.toString()
  })
} else {
  console.log(`Bugsnag was disabled in ${ process.env.NODE_ENV.toString() } enviroment`)
}

window.Bugsnag = Bugsnag
